import React, { useState } from "react";
import Accordion, { AccordionBody, AccordionHeader } from "./UI/Accordion";
import { GoDotFill } from "react-icons/go";
import Button from "./UI/Button";

const EventAccordion = ({ items, onEdit, onDelete }) => {
  const [current, setCurrent] = useState(-1);

  return (
    <Accordion defaultActiveKey="0">
      {items.map((item, i) => (
        <div key={i}>
          <AccordionHeader
            active={i === current}
            index={i}
            onClick={() => setCurrent((prev) => (prev === i ? -1 : i))}
          >
            <span>{item.eventName}</span>
            <GoDotFill color="#40A3B5" />
            <span>{item.eventVenue}</span>
            {onEdit && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(item);
                }}
                className="ms-3 rounded"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
              
            )}
            {onDelete && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(item);
                }}
                className="ms-3 rounded"
              >
                <i className="zmdi zmdi-delete"></i>
              </Button>
              
            )}
          </AccordionHeader>
          <AccordionBody active={i === current}>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Event Venue</th>
                  <td>{item.eventVenue}</td>
                </tr>
                <tr>
                  <th>Customer Details</th>
                  <td>{item.customerDetails}</td>
                </tr>
                <tr>
                  <th>Event Date</th>
                  <td>{item.eventDatetime}</td>
                </tr>
              </tbody>
            </table>
          </AccordionBody>
        </div>
      ))}
    </Accordion>
  );
};

export default EventAccordion;
