
const initialState = {
   userdetails:''
 };
 const homeReducer = (state = initialState, action) => {
    switch (action.type) {
       case 'userdetails':
          return {...state,userdetails:action.payload}    
       default:
          return state;
    }
 }
 export default homeReducer;