import { theme } from "../../../constants";
import { handleState } from "../../../functions";

const Switch = ({
  checked,
  label = "Switch",
  isRound = true,
  setState,
  name,
}) => {
  const css = {
    container: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    label: { fontSize: "14px" },
    switch: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: checked ? "flex-end" : "flex-start",
      width: "50px",
      height: "25px",
      backgroundColor: checked ? theme.yellow : "#808080",
      transition: "background-color 0.4s, justify-content 0.4s",
      borderRadius: isRound ? "50px" : "0%",
      padding: "4px",
      cursor: "pointer",
    },
    button: {
      width: "18px",
      height: "18px",
      backgroundColor: "#fff",
      transition: "transform 0.4s",
      borderRadius: isRound ? "50%" : "0%",
    },
  };

  const handleToggle = () => {
    handleState(setState, "reverse_value", name);
  };

  return (
    <div style={css.container}>
      <span style={css.label}>{label}:</span>
      <div style={css.switch} onClick={handleToggle}>
        <span style={css.button}></span>
      </div>
    </div>
  );
};

export default Switch;
