import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "./commonComponent/UI/Button";
import axios from "axios";
import { baseUrl } from "./helper";
import { useSelector } from "react-redux";
import ResponseAlert from "../components/commonComponent/Alert";
import { errObj, getError } from "../functions";
import Input from "./commonComponent/Form/Input";
import ImageUpload from "./commonComponent/Form/ImageUpload";
import ItemList from "./commonComponent/ItemList";
import Modal from "./commonComponent/UI/Modal";
import SearchBox from "./commonComponent/UI/SearchBox";
import { theme } from "../constants";
import { Spinner } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import Swal from "sweetalert2";

export default function EventItems() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([]);
  const userDetails = useSelector((state) => state.homeReducer.userdetails);

  const [editedId, setEditedId] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [itemProperty, setItemProperty] = useState({});
  const [inputFields, setInputFields] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const httpOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userDetails.token}`,
    },
  };

  const initialForm = useMemo(
    () => ({
      name: "",
      type: "",
      description: "",
      cost: "",
      image: "",
    }),
    []
  );
  const initialError = useMemo(
    () => ({
      name: errObj(true, "Item name required!"),
      type: errObj(true, "Item type required!"),
      description: errObj(),
      cost: errObj(true, "Item cost required!"),
      image: errObj(true, "Item image required!"),
    }),
    []
  );
  const initialAlert = useMemo(() => ({ type: "", message: "" }), []);
  const [form, setForm] = useState(initialForm);
  const [error, setError] = useState(initialError);
  const [alert, setAlert] = useState(initialAlert);

  const [searchPopup, setSearchPopup] = useState(false);

  const [searchTxt, setSearchTxt] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  const fetchItems = async () => {
    try {
      const response = await axios.get(baseUrl + "event/items", httpOptions);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const onSearchSelectItem = (popState, curTxt) => {
    setSearchTxt(curTxt);
    setSearchPopup(popState);
    applyFilteritem(curTxt);
  }

  // on clicking on edit btn
  function edit(item) {
    setIsEdit(true);
    setModalShow(false);
    setEditedId(item.itemId);
    setForm({
      name: item.itemName,
      type: item.itemType,
      description: item.itemDesc,
      cost: item.itemCost,
      image: item.itemImagePath,
    });
    setError({
      name: errObj(),
      type: errObj(),
      description: errObj(),
      cost: errObj(),
      image: errObj(),
    });

    const fields = item.itemAddFields.split(" ").map((elem, ind) => {
      return {
        item: ind,
        itemValue: elem,
      };
    });
    setInputFields(fields);

    handleShow();
  }

  const queryClient = useQueryClient();

  function deleteItem(item) {
    axios
      .put(baseUrl + `event/items/delete/${item.itemId}`, data, httpOptions)
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          setModalShow(false);
          queryClient.invalidateQueries("items");
          fetchItems();
          setAlert({ type: "success", message: "Item deleted successfully!" });
          setTimeout(() => setAlert(initialAlert), 3000);
        } else {
          setAlert({ type: "danger", message: response.message });
        }
        setLoading(false);
      })
      .catch(function (error) {
        setAlert({ type: "danger", message: error.response.data.message });
        setTimeout(() => setAlert(initialAlert), 3000);
        setLoading(false);
      });
  }

  // on submit item
  function addEvent() {
    setLoading(true);
    const err = getError(error);
    if (err.hasError) {
      setAlert({ type: "danger", message: err.message });
      setTimeout(() => setAlert(initialAlert), 3000);
      return;
    }

    let data = {
      emId: userDetails.emId,
      itemName: form.name,
      itemType: form.type,
      itemDesc: form.description,
      itemCost: form.cost,
      itemImagePath: form.image.startsWith("https")
        ? form.image
        : form.image.split(",")[1],
      itemAddFields: assignString(),
    };

    let urlPath = "event/additem";
    if (editedId) {
      data.itemId = editedId;
      urlPath = "event/updateitem";
    }

    axios
      .post(baseUrl + urlPath, data, httpOptions)
      .then(function (response) {
        if (response.status === 201) {
          handleClose();
          clearState();
          fetchItems();
          setAlert({ type: "success", message: "Item added successfully!" });
          setTimeout(() => setAlert(initialAlert), 3000);
        } else if (response.status === 200) {
          handleClose();
          clearState();
          fetchItems();
          setAlert({ type: "success", message: "Item updated successfully!" });
          setTimeout(() => setAlert(initialAlert), 3000);
        }
        setLoading(false);
        queryClient.invalidateQueries("items");
      })
      .catch(function (error) {
        setAlert({ type: "danger", message: error.response.data.message });
        setTimeout(() => setAlert(initialAlert), 3000);
        setLoading(false);
      });
  }
  const addFields = () => {
    let newfield = { item: inputFields.length, itemValue: "" };

    setInputFields([...inputFields, newfield]);
  };
  function AssigToItemDetailModal(item) {
    setItemProperty(item);
    setModalShow(true);
  }
  function assignString() {
    let str = "";
    inputFields.length > 0 &&
      inputFields.forEach((item) => {
        str = str.concat(" ", item.itemValue);
      });
    return str.replace(/\s+/g, " ").trim();
  }
  function clearState() {
    handleClose();
    setInputFields([]);
    setForm(initialForm);
    setError(initialError);
    setIsEdit(false);
  }
  const {
    data: datas,
    error: errors,
    isPending,
  } = useQuery("items", fetchItems);

  useEffect(() => {
    if (datas) {
      setData(datas);
      setFilterItems(datas);
    }
  }, [datas, errors]);
  function MyVerticallyCenteredModal() {
    if (isPending) return "Loading...";
    return (
      <Modal
        open={modalShow}
        setOpen={setModalShow}
        label="ITEM DETAILS"
        onClose={() => {
          setItemProperty({});
        }}
      >
        <div className="d-flex justify-content-end">
          <Button
            fontSize="14px"
            className="fw-bold ms-auto mb-3"
            onClick={() => edit(itemProperty)}
          >
            Edit
          </Button>
          {/* <Button
            fontSize="14px"
            className="fw-bold ms-auto mb-3"
            onClick={() => deleteItem(itemProperty)}
          >
            Delete
          </Button> */}
          <Button
            fontSize="14px"
            className="fw-bold ms-auto mb-3"
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text: "You will not be able to recover this item!",
                icon: "warning",
                iconColor: "#ffea00",
                background: "#f0f0f0",
                showCancelButton: true,
                confirmButtonText:
                  "<span style='color: black'>Yes, delete it!</span>",
                cancelButtonText: "No, keep it",
                confirmButtonColor: "#ffea00",
                confirmButtonTextColor: "#ffea00",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteItem(itemProperty);
                }
              });
            }}
          >
            Delete
          </Button>
        </div>
        <div className="item-details-wrapper">
          <div className="image-wrapper">
            <img src={itemProperty.itemImagePath} alt={itemProperty.itemName} />
          </div>
          <div className="item-details">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{itemProperty.itemName}</td>
                </tr>
                <tr>
                  <th>Type</th>
                  <td>{itemProperty.itemType}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{itemProperty.itemDesc}</td>
                </tr>
                <tr>
                  <th>Cost</th>
                  <td>{itemProperty.itemCost}</td>
                </tr>
                <tr>
                  <th>Others</th>
                  <td>{itemProperty.itemAddFields}</td>
                </tr>

                {/* <tr>
                    <th>Material</th>
                    <td>PVC Coated Fabric</td>
                  </tr>

                  <tr>
                    <th>Age Group</th>
                    <td>4-16 year</td>
                  </tr>
                  <tr>
                    <th>Application</th>
                    <td>Amusement Park, Malls</td>
                  </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    );
  }
  function search(e) {
    setSearchTxt(e);
    if (e === "") {
      setFilterItems(data);
      setSearchPopup(false);
    }
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].itemType.toLowerCase().includes(e.toLowerCase())) {
        let condition = temp.find(
          (item) => item.toLowerCase() === data[i].itemType.toLowerCase()
        );

        if (!condition) {
          temp.push(data[i].itemType);
        }
      }
      if (data[i].itemName.toLowerCase().includes(e.toLowerCase())) {
        temp.push(data[i].itemName);
      }
    }

    if (temp.length > 0) {
      setSearchPopup(true);
    }
    setFilterData(temp);
  }
  function applyFilteritem(searchText = searchTxt) {
    let filterData =
      data.length > 0 &&
      data.filter(
        (item) =>
          item.itemType.toLowerCase().includes(searchText.toLowerCase()) ||
          item.itemName.toLowerCase().includes(searchText.toLowerCase())
      );
    setFilterItems(filterData);
    setSearchPopup(false);
  }

  const handleFormChange = (index, event) => {
    let data = [...inputFields];

    data[index].itemValue = event.target.value;
    setInputFields(data);
  };
  return (
    <>
      <section>
        <div className="wrapper event-expert-main">
          <div className="container">
            {!show && (
              <ResponseAlert variant={alert.type} message={alert.message} />
            )}
            <div
              className="my-4 bg-gradient rounded-3 d-flex align-items-center justify-content-between"
              style={{ padding: "30px", background: theme.light }}
            >
              <div className="search-left">
                <div className="search">
                  <SearchBox
                    onChange={(e) => search(e.target.value)}
                    value={searchTxt}
                    setState={setSearchTxt}
                    onSearch={() => applyFilteritem()}
                    searchData={filterData}
                    onSearchSelect={onSearchSelectItem}
                    showSearchItems={searchPopup}
                    width={"300px"}
                  />
                </div>
              </div>
              <div>
                <Button
                  onClick={handleShow}
                  fontSize="14px"
                  text={"Add Item"}
                  startIcon={<i className="zmdi zmdi-collection-plus"></i>}
                />

                <Modal
                  open={show}
                  setOpen={setShow}
                  label={isEdit ? "EDIT ITEM" : "ADD ITEM"}
                  onClose={clearState}
                  onSubmit={addEvent}
                >
                  <ResponseAlert variant={alert.type} message={alert.message} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "14px",
                    }}
                  >
                    {loading && (
                      <div className="loading-text">
                        Saving<span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </div>
                    )}
                    <Input
                      name="name"
                      label="NAME"
                      placeholder="Enter Name"
                      value={form.name}
                      setState={setForm}
                      setError={setError}
                      required
                    />
                    <Input
                      name="type"
                      label="TYPE"
                      placeholder="Enter Type"
                      value={form.type}
                      setState={setForm}
                      setError={setError}
                      required
                    />
                    <Input
                      name="description"
                      label="DESCRIPTION"
                      placeholder="Enter Description"
                      value={form.description}
                      setState={setForm}
                      setError={setError}
                    />
                    <Input
                      type="number"
                      name="cost"
                      label="COST"
                      placeholder="Enter Cost"
                      value={form.cost}
                      setState={setForm}
                      setError={setError}
                      min={0}
                      required
                    />
                    <ImageUpload
                      accept=".jpg, .png, .gif"
                      name="image"
                      setState={setForm}
                      setError={setError}
                      label="IMAGE"
                      placeholder="No file selected"
                      image={form.image}
                      required
                      showPreview
                    />
                    {typeof inputFields === "string" ? (
                      <>
                        {inputFields.split(" ").map((item, i) => (
                          <Input
                            key={i}
                            value={item}
                            placeholder="Enter the value (ex: Height = 2ft)"
                            onChange={(e) => handleFormChange(i, e)}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {inputFields.length > 0 &&
                          inputFields.map((item, i) => (
                            <Input
                              key={i}
                              value={item.itemValue}
                              placeholder="Enter the value (ex: Height = 2ft)"
                              onChange={(e) => handleFormChange(i, e)}
                            />
                          ))}
                      </>
                    )}
                    <div className="add-fields">
                      <Button
                        variant="light"
                        className="d-flex align-items-center gap-2 float-end"
                        onClick={() => addFields()}
                      >
                        <i className="zmdi zmdi-collection-plus"></i>
                        <span>Add Item Fields</span>
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            <MyVerticallyCenteredModal />
            <ItemList
              items={filterItems}
              onClickOnCard={AssigToItemDetailModal}
              showCost={true}
            />
          </div>
        </div>
      </section>
    </>
  );
}
