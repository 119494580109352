import moment from "moment";
import React, { useMemo } from "react";

const NotificationItem = ({ data, onClick }) => {
  const { noStatus, noMessage, noDate } = data;
  const isReaded = useMemo(() => noStatus === "Read", [noStatus]);

  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        borderBottom: "1px solid #bababa",
        fontSize: "14px",
        padding: "10px",
        letterSpacing: "-0.5px",
        cursor: "pointer",
        fontWeight: isReaded ? "normal" : "bold",
        background: "white",
      }}
    >
      <div>{noMessage}</div>
      <div
        style={{
          justifyContent: "space-between",
          width: "100%",
          paddingRight: "14px",
        }}
      >
        {moment(noDate).format("L")}
      </div>
    </div>
  );
};

export default NotificationItem;
