import React from "react";
import Input from "../Form/Input";
import { AiOutlineSearch } from "react-icons/ai";
import { theme } from "../../../constants";

// const SearchBox = ({
//   onSearch,
//   placeholder = "Search here...",
//   value,
//   setState,
//   onChange,
//   className,
//   showSearchItems,
//   onSearchSelect,
//   searchData,
//   width,
// }) => {
//   return (
//     <div className="d-flex shadow-sm position-relative">
//       <Input
//         className={className}
//         type="search"
//         borderColor="white"
//         rounded="5px 0px 0px 5px"
//         setState={setState}
//         value={value}
//         placeholder={placeholder}
//         onChange={onChange}
//         width={width}
//         onKeyPress={onSearch}
//       />
//       <button
//         className="d-flex align-items-center justify-content-center border-0"
//         style={{
//           width: "40px",
//           background: theme.yellow,
//           borderRadius: "0px 5px 5px 0px",
//         }}
//         onClick={onSearch}
//       >
//         <AiOutlineSearch size={24} color="black" />
//       </button>

//       {showSearchItems && (
//         <div
//           className="position-absolute bg-light rounded-3 shadow-sm overflow-hidden text-dark"
//           style={{ zIndex: "100", width: width || "238px", top: "40px" }}
//         >
//           {value &&
//             searchData?.map((item, i) => (
//               <div
//                 key={i}
//                 role="button"
//                 className="p-2 border-bottom hover-btn"
//                 onClick={() => {
//                   setState(item);
//                   onSearchSelect && onSearchSelect(false);
//                 }}
//               >
//                 {item}
//               </div>
//             ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default SearchBox;
const SearchBox = ({
  onSearch,
  placeholder = "Search here...",
  value,
  setState,
  onChange,
  className,
  showSearchItems,
  onSearchSelect,
  searchData,
  width,
}) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <div
      className="d-flex shadow-sm position-relative"
      onKeyPress={handleKeyPress}
    >
      <Input
        className={className}
        type="search"
        borderColor="white"
        rounded="5px 0px 0px 5px"
        // setState={setState}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        width={width}
      />
      <button
        className="d-flex align-items-center justify-content-center border-0"
        style={{
          width: "40px",
          background: theme.yellow,
          borderRadius: "0px 5px 5px 0px",
        }}
        onClick={onSearch}
      >
        <AiOutlineSearch size={24} color="black" />
      </button>

      {showSearchItems && (
        <div
          className="position-absolute bg-light rounded-3 shadow-sm overflow-hidden text-dark"
          style={{ zIndex: "100", width: width || "238px", top: "40px" }}
        >
          {value &&
            searchData?.map((item, i) => (
              <div
                key={i}
                role="button"
                className="p-2 border-bottom hover-btn"
                onClick={() => {
                  onSearchSelect && onSearchSelect(false, item);
                }}
              >
                {item}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SearchBox;
