import React, { useEffect, useMemo, useState } from "react";
import eventLogo from "../../assets/images/logo.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../helper";
import { useSelector } from "react-redux";
import axios from "axios";
import NotificationItem from "./NotificationItem";
import NotificationFilterButtons from "./NotificationFilterButtons";
import { userDetails } from "../Redux/Action";

export default function TopHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.homeReducer.userdetails);
  const [status, setStatus] = useState("");
  const [notify, setNotify] = useState([]);
  const [notificationFilter, setNotificationFilter] = useState("Unread");
  const userDetail = useSelector((state) => state.homeReducer.userdetails);

  const httpOptions = {
    headers: {
    "Content-Type": "application/json",
    'Authorization': `Bearer ${userDetail.token}`
  }
}

  useEffect(() => {
    getNotify();
  }, []);

  function readNotify(item) {
    let data = [
        item.noId,
    ];
    axios
      .post(baseUrl + "event/readnotifications", data, httpOptions)
      .then(function (response) {
        if (response.status === 200) {
          getNotify();
        }
      })
      .catch(function (error) {
        // setStatus(true);
        setStatus("failed");
        setTimeout(() => setStatus(""), 1000);
      });
  }
  function getNotify() {

    axios
      .get(baseUrl + "event/notifications", httpOptions)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response);
          setNotify(response.data);
        }
      })
      .catch(function (error) {
        // setStatus(true);
        setStatus("failed");
        setTimeout(() => setStatus(""), 1000);

        // ResponseAlert({variant:"warning",message:"Something went wrong..."})
        // alert("Something went wrong...")
      });
  }
  const filteredNotification = useMemo(
    () =>
      notify.filter((elem) => {
        if (notificationFilter === "All") {
          return true;
        } else {
          return elem.noStatus === notificationFilter;
        }
      }),
    [notificationFilter, notify]
  );

  return (
    <>
      <div className="header-wrapper">
        <div className="logo">
          <a href="\" alt="Event Expert Logo">
            <img src={eventLogo} alt="EventExperts" />
          </a>
        </div>
        <div className="profile-wrapper">
          <div className="header-profile-layer">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-notification"
                className="notification-btn"
              >
                <i
                  className="zmdi zmdi-notifications-none"
                  onClick={() => getNotify()}
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{ width: "400px" }}
                className="dd-notification-wrapper"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: "#FFEA00",
                  }}
                >
                  <h3>Notifications</h3>
                  <NotificationFilterButtons
                    setState={setNotificationFilter}
                    value={notificationFilter}
                  />
                </div>
                {!filteredNotification.length && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "40px 0px",
                      background: "lightgray",
                    }}
                  >
                    No Notifications
                  </div>
                )}
                {filteredNotification?.map((item, i) => (
                  <NotificationItem
                    key={i}
                    data={item}
                    onClick={() =>
                      item.noStatus === "Unread" && readNotify(item)
                    }
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-profile"
                className="profile-btn"
              >
                {/* <img src={profilePic} alt="profile" /> */}
                <i className="zmdi zmdi-account"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(userDetails(""));
                    navigate("/login", { replace: true });
                  }}
                >
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    Logout
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}
