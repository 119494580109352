import registrationFormPic from "../assets/images/registration-form-6.jpg";
import React, { useState, useMemo } from "react";
import axios from "axios";
import { baseUrl } from "./helper";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ResponseAlert from "../components/commonComponent/Alert";
import Button from "./commonComponent/UI/Button";

export default function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [eventOrg, setEventOrg] = useState("");

  const initialAlert = useMemo(() => ({ type: "", message: "" }), []);
  const [alert, setAlert] = useState(initialAlert);

  const [status, setStatus] = useState("");

  const navigate = useNavigate();

  function submit(e) {
    e.preventDefault();

    let data = {
      firstName: firstName,
      lastName: lastName,
      password: password,
      matchingPassword: matchPassword,
      emailId: emailId,
      phoneNumber: mobileNo,
      emEventOrg: eventOrg,
    };
    axios
      .post(baseUrl + "users/signup", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status === 201) {
          Swal.fire({
            title: "Congrats!",
            text: "Registered successfully!",
            icon: "success",
            iconColor: "#00d9ff",
            background: "#f0f0f0",
            confirmButtonColor: "#ffea00",
          });
          navigate("/Login", { replace: true });
        }
      })
      .catch(function (error) {
        // alert("something went wrong....")
        setAlert({ type: "danger", message: error.response.data.message });
        setTimeout(() => setAlert(initialAlert), 3000);
        setTimeout(() => {
          setAlert(initialAlert);
        }, 5000);
      });
  }
  return (
    <>
      <div className="wrapper">
        <div className="inner">
          <div className="image-holder">
            <img src={registrationFormPic} alt="" />
          </div>

          <div className="signin-wrapper registration">
            <form>
              {alert.type && (
                <ResponseAlert variant={alert.type} message={alert.message} />
              )}
              <h3>Create Account</h3>
              <div className="form-row">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="form-row">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Mail"
                  onChange={(e) => setEmailId(e.target.value)}
                />
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Phone"
                  onChange={(e) => setMobileNo(e.target.value)}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Event Org Name"
                  onChange={(e) => setEventOrg(e.target.value)}
                />
              </div>

              <div className="form-row">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Set Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="password"
                  className="form-control"
                  placeholder="Retype Password"
                  onChange={(e) => setMatchPassword(e.target.value)}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <Button onClick={(e) => submit(e)}>
                  Submit
                  <i className="zmdi zmdi-long-arrow-right"></i>
                </Button>
              </div>
            </form>
            <div className="new-registration">
              <div className="a-divider-break">
                <h5 aria-level="5">Already have an EventExperts account? </h5>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button onClick={() => navigate("/Login", { replace: true })}>
                  Sign in <i className="zmdi zmdi-long-arrow-right"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
