import "bootstrap/dist/css/bootstrap.css";
import "./assets/fonts/material-design-iconic-font/css/material-design-iconic-font.min.css";
import "./App.css";

import TopHeader from "./components/commonComponent/TopHeader";
import MainNavigation from "./components/commonComponent/MainNavigation";
import Footer from "./components/commonComponent/Footer";
import Dashboard from "./components/Dashboard";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";

import EventItems from "./components/EventItems";
import EventDetails from "./components/EventDetails";
import CopyUrl from "./components/CopyUrl";
import { Routes, Route } from "react-router-dom";

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
function App() {
  const userDetails = useSelector((state) => state.homeReducer.userdetails);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    !userDetails.emId &&
      !location.pathname.startsWith("/Register") && !location.pathname.startsWith("/Home/auth") &&
      navigate("/Login", { replace: true });
  }, [location.pathname, navigate, userDetails.emId]);

  return (
    <QueryClientProvider client={queryClient}>
    <div className="App">
      <header>
        {userDetails.emId && <TopHeader />}
        {userDetails.emId && <MainNavigation />}
      </header>
      <Routes>
        <Route path="Login" element={<Login />} />
        <Route path="Register" element={<Register />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="home" element={<Home />} />
        <Route path="/Home/auth/:hash/:showPrice?" element={<CopyUrl />} />
        <Route path="EventDetails" element={<EventDetails />} />
        <Route path="EventItems" index element={<EventItems />} />
      </Routes>
      {userDetails.emId && <Footer />}
    </div>
    </QueryClientProvider>
  );
}

export default App;
