import React, { useState, useEffect } from "react";
import Button from "./commonComponent/UI/Button";
import axios from "axios";
import { baseUrl } from "./helper";
import moment from "moment";
import { useSelector } from "react-redux";
import ResponseAlert from "../components/commonComponent/Alert";
import { errObj, getError } from "../functions";
import Input from "../components/commonComponent/Form/Input";
import Textarea from "./commonComponent/Form/Textarea";
import Modal from "./commonComponent/UI/Modal";
import Box from "./commonComponent/UI/Box";
import EventAccordion from "./commonComponent/EventAccordion";
import { theme } from "../constants";
import { useQuery, useQueryClient } from "react-query";
import Swal from "sweetalert2";

export default function EventDetails() {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    clearStateData();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const userDetails = useSelector((state) => state.homeReducer.userdetails);

  const [isModalTwoOpen, setIsModalTwoOpen] = useState(false);
  const handleCloseTwo = () => setIsModalTwoOpen(false);
  const handleShowTwo = () => {
    editediItemDetails();
    setIsModalTwoOpen(true);
  };
  const [eventItems, setEventItems] = useState([]);
  const [eventDetails, setEventDetails] = useState({
    pastDetails: [],
    futureDetails: [],
  });

  const [eventId, setEventId] = useState("");
  const [edit, setEdit] = useState("");
  const [loading, setLoading] = useState(false);

  const initialForm = {
    name: "",
    venue: "",
    customerDetails: "",
    eventDate: "",
    paymentDate: "",
  };
  const initialError = {
    name: errObj(true, "Name is requried!"),
    venue: errObj(),
    customerDetails: errObj(),
    eventDate: errObj(true, "Event Date is required!"),
    paymentDate: errObj(),
  };
  const initialAlert = { type: "", message: "" };
  const [form, setForm] = useState(initialForm);
  const [addItemDetails, setAddItemDetails] = useState([]);
  const [error, setError] = useState(initialError);
  const [alert, setAlert] = useState(initialAlert);
  const [data, setData] = useState([]);

  const httpOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userDetails.token}`,
    },
  };

  const queryClient = useQueryClient();

  function getItems() {
    axios
      .get(baseUrl + "event/items", httpOptions)
      .then(function (response) {
        if (response.status === 200) {
          let data = [];
          response.data.length > 0 &&
            response.data.map((item) => {
              item.quantity = 0;

              data.push(item);
              // masterData.push(item);
            });

          setEventItems(data);
        }
      })
      .catch(function (error) {
        // alert('Something went wrong....')
      });
  }
  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${baseUrl}event/events`, httpOptions);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const { data: eventDetailes, error: eventError } = useQuery(
    "events",
    fetchEvents
  );

  useEffect(() => {
    if (eventDetailes) {
      let pastDetails = [];
      let futureDetails = [];
      eventDetailes.forEach((item) => {
        const dateFormat = "DD-MM-yyyy";
        const eventDate = moment(item.eventDatetime, dateFormat, true);

        if (eventDate.isValid()) {
          if (eventDate.isAfter(moment(), "day")) {
            futureDetails.push(item);
          } else {
            pastDetails.push(item);
          }
        } else {
          const formattedDate = moment(new Date(item.eventDatetime)).format(
            dateFormat
          );

          if (moment(formattedDate, dateFormat).isAfter(moment(), "day")) {
            item.eventDatetime = formattedDate;
            futureDetails.push(item);
          } else {
            item.eventDatetime = formattedDate;
            pastDetails.push(item);
          }
        }
      });
      let data = {
        pastDetails: pastDetails,
        futureDetails: futureDetails,
      };
      setEventDetails(data);
    }
  }, [eventDetailes]);

  function addEventDetails() {
    setLoading(true);
    const err = getError(error);
    if (err.hasError) {
      setAlert({ type: "danger", message: err.message });
      setTimeout(() => {
        setAlert(initialAlert);
      }, 2000);
      return;
    }
    axios
      .post(
        baseUrl + "event/addevent",
        {
          emId: userDetails.emId,
          eventName: form.name,
          eventDate: moment(form.eventDate).format("yyyy-MM-DD"),
          eventDatetime: `${moment(form.eventDate).format(
            "yyyy-MM-DD"
          )}T22:54:01.754Z`,
          paymentDate: form.paymentDate,
          eventVenue: form.venue,
          customerDetails: form.customerDetails,
          eventItemsList: deleteName(),
        },
        httpOptions
      )
      .then(function (response) {
        setLoading(false);
        if (response.status === 201) {
          queryClient.invalidateQueries("events");
          clearStateData();
          handleClose();
          handleCloseTwo();
          setAlert({ type: "success", message: "Event added successfully!" });
          setTimeout(() => setAlert(initialAlert), 2000);
        }
      })
      .catch((e) => {
        setLoading(false);
        setAlert({ type: "danger", message: e.response.data.message });
        setTimeout(() => setAlert(initialAlert), 2000);
      });
  }

  function getquotation() {
    const handleDownload = async () => {
      const requestData = {
        emId: userDetails.emId,
        eventName: form.name,
        eventDate: moment(form.eventDate).format("yyyy-MM-DD"),
        eventDatetime: `${moment(form.eventDate).format(
          "yyyy-MM-DD"
        )}T22:54:01.754Z`,
        paymentDate: form.paymentDate,
        eventVenue: form.venue,
        customerDetails: form.customerDetails,
        eventItemsList: deleteName(),
      };

      try {
        const response = await fetch(baseUrl + "event/generatequotation", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userDetails.token}`,
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          const blob = await response.blob();

          // Create a URL for the Blob object
          const url = URL.createObjectURL(blob);

          // Create a temporary anchor tag
          const link = document.createElement("a");
          link.href = url;
          link.download = `${form.name}.docx`; // Specify the filename

          // Simulate a click on the anchor tag to initiate the download
          link.click();

          // Clean up by revoking the URL object
          URL.revokeObjectURL(url);
        } else {
          console.error(
            "Failed to download file. Status code: " + response.status
          );
        }
      } catch (error) {
        console.error("Error occurred while downloading the file:", error);
      }
    };
    handleDownload();
  }

  function editEventDetails() {
    setLoading(true);
    const err = getError(error);
    if (err.hasError) {
      setAlert({ type: "danger", message: err.message });
      setTimeout(() => {
        setAlert(initialAlert);
      }, 2000);
      return;
    }

    axios
      .put(
        baseUrl + "event/updateevent",
        {
          emId: userDetails.emId,

          eventName: form.name,
          eventDate: moment(form.eventDate).format("yyyy-MM-DD"),
          eventDatetime: `${moment(form.eventDate).format(
            "yyyy-MM-DD"
          )}T12:54:01.754Z`,
          eventId: eventId,
          paymentDate: form.paymentDate,
          eventVenue: form.venue,
          customerDetails: form.customerDetails,
          eventItemsList: deleteName(),
        },
        httpOptions
      )
      .then(function (response) {
        setLoading(false);
        if (response.status === 200) {
          queryClient.invalidateQueries("events");
          queryClient.setQueryData("events", (oldData) => {
            return oldData.map((event) =>
              event.eventId === eventId
                ? { ...event, ...response.data }
                : event
            );
          });
          clearStateData();
          handleClose();
          handleCloseTwo();
          setAlert({ type: "success", message: "Event updated Successfully!" });
          setTimeout(() => setAlert(initialAlert), 2000);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert({ type: "danger", message: error.data });

        setTimeout(() => setAlert(initialAlert), 2000);
      });
  }

  function clearStateData() {
    setForm(initialForm);
    setError(initialError);
    setAddItemDetails([]);
    setEdit(false);
    setEventId("");
  }

  function editediItemDetails() {
    addItemDetails.length > 0 &&
      addItemDetails.map((item) => AddEventItem(item, item.quantity));
  }
  function AddEventItem(item, quantity) {
    let data = [...eventItems];
    for (let i = 0; i <= data.length; i++) {
      if (data[i]?.itemId === item?.itemId) {
        data[i].quantity = quantity;
      }
    }

    setEventItems(data);
  }

  function editEvents(item) {
    getItems();
    setEdit(true);
    // getItems();
    handleShow();
    setForm({
      name: item.eventName,
      venue: item.eventVenue,
      customerDetails: item.customerDetails,
      eventDate: new Date(item.eventDate).toISOString().split("T")[0],
      paymentDate: new Date(item.paymentDate).toISOString().split("T")[0],
    });

    setError({
      name: errObj(),
      venue: errObj(),
      customerDetails: errObj(),
      eventDate: errObj(),
      paymentDate: errObj(),
    });
    setEventId(item.eventId);
    setAddItemDetails(item.eventItemsList);
  }

  function deleteEvent(item) {
    axios
      .put(baseUrl + `event/delete/${item.eventId}`, data, httpOptions)
      .then(function (response) {
        if (response.status === 201 || response.status === 200) {
          setAlert({ type: "success", message: "Event deleted successfully!" });
          queryClient.invalidateQueries("events");
          queryClient.setQueryData("events", (oldData) =>
            oldData.filter((event) => event.eventId !== item.eventId)
          );
          setTimeout(() => setAlert(initialAlert), 3000);
        } else {
          setAlert({ type: "danger", message: response.message });
        }
      })
      .catch(function (error) {
        setAlert({ type: "danger", message: error.response.data.message });
        setTimeout(() => setAlert(initialAlert), 3000);
      });
  }

  function modalTwoSubmit() {
    // let masterData=eventItems
    let tempData = [];
    eventItems.length > 0 &&
      eventItems.forEach((item) => {
        if (item.quantity > 0) {
          let data = {
            itemId: item.itemId,
            itemName: item.itemName,
            quantity: Number(item.quantity),
          };
          tempData.push(data);
        }
      });
    setAddItemDetails(tempData);
    handleCloseTwo();
  }

  function deleteName() {
    let data =
      addItemDetails.length > 0 &&
      addItemDetails.map((item) => {
        delete item.itemName;
        return item;
      });


    return data || [];
  }

  return (
    <>
      <section>
        <div className="event-expert-main">
          <Box maxWidth={"800px"} bg="transparent" className="mx-auto px-4">
            {!show && (
              <ResponseAlert variant={alert.type} message={alert.message} />
            )}
            <div
              className="d-flex justify-content-between align-items-center p-4 my-4 bg-gradient rounded"
              style={{ background: theme.light }}
            >
              <div>
                <span className="fw-bold fs-5">Events Detail</span>
              </div>
              <div className="search-right">
                <div className="add-item">
                  <Button
                    onClick={() => {
                      handleShow();
                      getItems();
                    }}
                  >
                    <i className="zmdi zmdi-collection-plus"></i>
                    <span>Add Event</span>
                  </Button>
                  <Modal
                    open={show}
                    onClose={handleClose}
                    setOpen={setShow}
                    onSubmit={() =>
                      edit ? editEventDetails() : addEventDetails()
                    }
                    label={edit ? "EDIT EVENT" : "ADD EVENT"}
                  >
                    <ResponseAlert
                      variant={alert.type}
                      message={alert.message}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "14px",
                      }}
                      className="add-item-form"
                    >
                      {loading && (
                        <div className="loading-text">
                          Saving<span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </div>
                      )}
                      <Input
                        label="NAME"
                        placeholder="Enter event name"
                        value={form.name}
                        setState={setForm}
                        setError={setError}
                        name="name"
                        required
                      />
                      <Input
                        label="VENUE"
                        placeholder="Enter event venue"
                        value={form.venue}
                        setState={setForm}
                        setError={setError}
                        name="venue"
                      />
                      <Textarea
                        label="CUSTOMER DETAILS"
                        placeholder="Enter customer details"
                        value={form.customerDetails}
                        setState={setForm}
                        setError={setError}
                        name="customerDetails"
                      />
                      <Input
                        type="date"
                        label="EVENT DATE"
                        placeholder="Enter event date"
                        value={form.eventDate}
                        setState={setForm}
                        setError={setError}
                        name="eventDate"
                        required
                      />
                      <Input
                        type="date"
                        label="PAYMENT DATE"
                        placeholder="Enter payment date"
                        value={form.paymentDate}
                        setState={setForm}
                        setError={setError}
                        name="paymentDate"
                      />
                      <div className="event-list-wrapper">
                        <div>
                          {addItemDetails.length > 0 && (
                            <div className="event-list-item">
                              <h3>Added Items for Event</h3>
                              <ul>
                                {addItemDetails.length > 0 &&
                                  addItemDetails.map((item, i) => (
                                    <li key={i}>
                                      {item.itemName} ={" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {item.quantity}
                                      </span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="add-fields">
                            <Button
                              className="d-flex gap-2 align-items-center float-end"
                              onClick={() => {
                                edit && editediItemDetails();
                                handleShowTwo();
                              }}
                            >
                              <i className="zmdi zmdi-collection-plus"></i>
                              <span>Add Items for Event</span>
                            </Button>
                          </div>
                          {/* Second Modal */}
                          <Modal
                            open={isModalTwoOpen}
                            setOpen={setIsModalTwoOpen}
                            onClose={handleCloseTwo}
                            onSubmit={modalTwoSubmit}
                            label="Add Items for Event"
                          >
                            <ResponseAlert
                              variant={alert.type}
                              message={alert.message}
                            />
                            <div className="add-items-for-event">
                              {eventItems.length > 0 &&
                                eventItems.map((item, i) => {
                                  return (
                                    <div className="event-item">
                                      <div className="content">
                                        <div className="image-wrapper">
                                          <img
                                            src={
                                              item.itemImagePath
                                                ? item.itemImagePath
                                                : require("../assets/images/DUMMYIMG.png")
                                            }
                                            alt={item.itemName}
                                          />
                                        </div>
                                        <div className="event-type">
                                          <h4>{item.itemType}</h4>
                                        </div>
                                        <div className="event-name">
                                          <h3>{item.itemName}</h3>
                                          <div className="add-item-wrapper">
                                            <label>Quantity:</label>
                                            <input
                                              type="number"
                                              id="quantity"
                                              name="quantity"
                                              placeholder="0"
                                              value={item.quantity}
                                              min="0"
                                              max="10"
                                              onChange={(e) =>
                                                AddEventItem(
                                                  item,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="price-tag">
                                          {item.itemCost}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </Modal>
                          <div className="add-fields">
                            <Button
                              className="d-flex align-items-center gap-2 mt-2 float-end"
                              variant="light"
                              onClick={() =>
                                addItemDetails.length && getquotation()
                              }
                              disabled={addItemDetails.length === 0}
                            >
                              <i className="zmdi zmdi-quote"></i>
                              <span>Generate Quotation</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>

            <div className="events-wrapper mb-0">
              <h2 className="mb-0">
                <span>Upcoming Events</span>
              </h2>
              <EventAccordion
                items={eventDetails.futureDetails}
                onEdit={editEvents}
                onDelete={(event) => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You will not be able to recover this event!",
                    icon: "warning",
                    background: "#f0f0f0",
                    iconColor: "#ffea00",
                    showCancelButton: true,
                    confirmButtonText:
                      "<span style='color: black'>Yes, delete it!<span>",
                    cancelButtonText: "No, keep it",
                    confirmButtonColor: "#ffea00",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteEvent(event);
                      Swal.fire(
                        "Deleted!",
                        "Your event has been deleted.",
                        "success"
                      );
                    }
                  });
                }}
              />

              <h2>
                <span>Past Events</span>
              </h2>
              <EventAccordion items={eventDetails.pastDetails} />
            </div>
          </Box>
        </div>
      </section>
    </>
  );
}
