import { useCallback, useMemo, useState } from "react";
import { capitalize, errObj, handleState } from "../../../functions";

const Textarea = ({
  value,
  setState,
  name,
  subField,
  placeholder = "Type..",
  label,
  setError,
  required,
  minLength,
  maxLength,
  onChange,
  cols,
  rows=3,
}) => {
  const [validation, setValidation] = useState(false);
  const borderColor = useMemo(
    () => (validation ? "red" : "#d6d6d6"),
    [validation]
  );

  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e);
        return;
      }
      const v = e.target.value;
      if (required && v.trim() === "") {
        handleState(
          setError,
          errObj(true, `${capitalize(label)} is required!`),
          name,
          subField
        );
        setValidation(true);
      } else {
        handleState(setError, errObj(), name, subField);
        setValidation(false);
      }

      handleState(setState, v, name, subField);
    },
    [onChange, required, setState, name, subField, setError, label]
  );

  const handleFocus = (event) => {
    event.target.style.borderColor = "gray"; // Change border color on focus
  };

  const handleBlur = (event) => {
    event.target.style.borderColor = borderColor;
  };

  const css = {
    textarea: {
      padding: "8px 10px",
      borderRadius: "2px",
      fontSize: "0.875rem",
      border: `1px solid ${borderColor}`,
      outline: "none",
      transition: "border-color 0.2s ease",
    },
    label: {
      textAlign: "end",
      fontSize: "0.7rem",
      fontWeight: "bold",
      color: "#494949",
    },
    textareaBlock: {
      display: "grid",
      gridTemplateColumns: "100px auto",
      gap: "5px",
      alignItems: "center",
    },
  };

  return (
    <div style={css.textareaBlock}>
      <div style={css.label}>
        {label} {required && "*"}
      </div>
      <textarea
        name={name}
        style={css.textarea}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        minLength={minLength}
        maxLength={maxLength}
        cols={cols}
        rows={rows}
      />
    </div>
  );
};

export default Textarea;
