const NotificationFilterButtons = ({ value, setState }) => {
  const handleButtonClick = (buttonName) => {
    setState(buttonName);
  };
  const buttons = ["Unread", "All"];

  const css = {
    container: {
      display: "flex",
      marginRight: "5px",
      padding: "2px",
      background: "white",
      gap: "5px",
      borderRadius: "4px",
      boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.3)",
    },
    dynamicbutton: (e) => {
      return {
        paddingInline: "5px",
        cursor: "pointer",
        background: value === e ? "white" : "",
        borderRadius: "4px",
        minWidth: "60px",
        boxShadow: value === e ? "0px 0px 10px #bababa" : "",
        textAlign: "center",
      };
    },
  };

  return (
    <div style={css.container}>
      {buttons.map((elem, index) => (
        <div
          key={index}
          style={css.dynamicbutton(elem)}
          onClick={() => handleButtonClick(elem)}
        >
          {elem}
        </div>
      ))}
    </div>
  );
};

export default NotificationFilterButtons;
