import React from "react";
import { colors } from "../../../constants";

const Box = ({
  className,
  children,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  bg = "#F5F5F5",
  color = colors.primary[1],
  style,
}) => {
  return (
    <div
      className={className}
      style={{
        width,
        height,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        background: bg,
        color,
      }}
    >
      {children}
    </div>
  );
};

export default Box;
