import underConsPic from "../assets/images/25509.jpg"
export default function Dashboard()
{
    return <>
    <div className="content-main yellow-bg">
        <div className="under-construction">
            <div className="uc-inner">
                <img src={underConsPic} alt="Under Construction" />
            </div>
        </div>
    </div>
    </>
}