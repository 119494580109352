import { Link, useLocation } from "react-router-dom";

const MainNavigation = () => {
  const links = [
    {
      path: "/home",
      label: "Home",
    },
    {
      path: "/EventItems",
      label: "Event Items",
    },
    {
      path: "/EventDetails",
      label: "Event Details",
    },
  ];
  const location = useLocation();

  return (
    <div className="d-flex gap-3 p-3 bg-dark bg-gradient px-5">
      {links.map((elem, ind) => (
        <Link
          key={ind}
          to={elem.path}
          className={
            location.pathname.startsWith(elem.path)
              ? "text-info"
              : "text-white"
          }
        >
          {elem.label}
        </Link>
      ))}
    </div>
  );
};

export default MainNavigation;
