import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";

export const AccordionHeader = ({ children, active, onClick, index }) => {
  return (
    <div
      onClick={onClick}
      className="d-flex align-items-center justify-content-between p-3"
      style={{
        borderTop: index !== 0 && "2px solid #b8eaf5",
        borderBottom: active && "2px solid #b8eaf5",
        background: "#CFF7FF",
        cursor: "pointer",
      }}
    >
      <span className="d-flex align-items-center gap-2">{children}</span>
      <FiChevronDown size={20} style={{ rotate: active ? "180deg" : "" }} />
    </div>
  );
};

export const AccordionBody = ({ children, active }) => {
  return (
    <motion.div
      className="bg-light"
      style={{
        overflow: "hidden",
      }}
      initial={{ height: "0px" }}
      animate={{ height: active ? "fit-content" : "0px" }}
      transition={{ type: "just", duration: "0.3" }}
    >
      <div className="p-3">{children}</div>
    </motion.div>
  );
};

const Accordion = ({ children }) => {
  return (
    <div
      className="my-5 rounded-3"
      style={{
        border: "1px solid #b8eaf5",
        overflow: "hidden",
        filter:
          "drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))",
      }}
    >
      {children}
    </div>
  );
};

export default Accordion;
