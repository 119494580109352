import React from "react";

const Ruppee = ({ value, className, children }) => {
  const formattedValue = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(value);

  return <span className={className}>{formattedValue}</span>;
};

export default Ruppee;
