import { colors } from "../../../constants";
import Box from "./Box";
import { MdOutlineClose } from "react-icons/md";
import Button from "./Button";
import { useCallback } from "react";
import { motion } from "framer-motion";

const Modal = ({ label, children, onClose, onSubmit, open, setOpen }) => {
  const handleClose = useCallback(
    (e) => {
      onClose && onClose(e);
      setOpen(false);
    },
    [onClose, setOpen]
  );

  const handleSubmit = useCallback(
    (e) => {
      onSubmit(e);
    },
    [onSubmit]
  );

  if (!open) return;

  return (
    <div
      style={{
        background: "#00000060",
        zIndex: "100",
        // paddingTop: "50px",
        backdropFilter: "blur(2px)",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: "0px",
        left: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <motion.div
        initial={{ y: -30, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ type: "just", duration: 0.3 }}
      >
        <Box
          width="100%"
          maxWidth="500px"
          minWidth="500px"
          className="overflow-hidden"
        >
          <Box
            className="p-3 text-uppercase fw-bold d-flex justify-content-between align-items-center gap-2"
            bg={colors.primary[0]}
          >
            <span>{label}</span>
            <span
              className="fs-5"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            >
              <MdOutlineClose />
            </span>
          </Box>

          <Box className="p-3 overflow-auto" maxHeight="70vh">
            {children}
          </Box>

          <Box className="p-3" bg={colors.dark[0]} color={colors.dark[1]}>
            <Box
              bg="transparent"
              width="fit-content"
              className="ms-auto d-flex align-items-center gap-3"
            >
              {setOpen && (
                <Button
                  minWidth="100px"
                  variant="secondary"
                  text="Close"
                  onClick={handleClose}
                />
              )}
              {onSubmit && (
                <Button minWidth="100px" text="Submit" onClick={handleSubmit} />
              )}
            </Box>
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default Modal;
