import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { baseUrl } from "./helper";
import ResponseAlert from "./commonComponent/Alert";
import { useParams } from "react-router-dom";
import ItemList from "./commonComponent/ItemList";
import Modal from "./commonComponent/UI/Modal";
import SearchBox from "./commonComponent/UI/SearchBox";
import { theme } from "../constants";

export default function CopyUrl() {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [itemProperty, setItemProperty] = useState({});
  const [status, setStatus] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [searchPopup, setSearchPopup] = useState(false);

  const [filterData, setFilterData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const { showPrice, hash } = useParams();
  const showPriceBool = useMemo(
    () => (showPrice === "true" ? true : false),
    [showPrice]
  );

  const getItems = useCallback(() => {

    axios
      .get(`${baseUrl}users/getdetailsbyhash?hash=${hash}&showCost=${showPriceBool}`)
      .then(function (response) {
        if (response.status === 200) {
          setData(response.data);
          setFilterItems(response.data.emItemsList);
        }
      })
      .catch(function () {
        setStatus("failed");
      });
  }, [hash, showPriceBool]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  function AssigToItemDetailModal(item) {
    setItemProperty(item);
    setModalShow(true);
  }
  function search(e) {
    setSearchTxt(e);
    if (e === "") {
      setFilterItems(data.emItemsList);
      setSearchPopup(false);
      return;
    }

    const items = data.emItemsList;
    let temp = [];

    for (let i = 0; i < items.length; i++) {
      const itemType = items[i].itemType.toLowerCase();
      const itemName = items[i].itemName.toLowerCase();

      if (
        itemType.includes(e.toLowerCase()) ||
        itemName.includes(e.toLowerCase())
      ) {
        if (!temp.includes(items[i])) {
          temp.push(items[i]);
        }
      }
    }

    setFilterItems(temp);
    setSearchPopup(true);
  }
  function applyFilteritem() {
    let filterData = data.emItemsList.filter(
      (item) =>
        item.itemType.toLowerCase().includes(searchTxt.toLowerCase()) ||
        item.itemName.toLowerCase().includes(searchTxt.toLowerCase())
    );
    setFilterItems(filterData);
    setSearchPopup(false);
  }

  function MyVerticallyCenteredModal() {
    return (
      <Modal
        open={modalShow}
        setOpen={setModalShow}
        label="ITEM DETAILS"
        onClose={() => {
          setItemProperty({});
        }}
      >
        <div className="item-details-wrapper">
          <div className="image-wrapper">
            <img src={itemProperty.itemImagePath} alt={itemProperty.itemName} />
          </div>
          <div className="item-details">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{itemProperty.itemName}</td>
                </tr>
                <tr>
                  <th>Type</th>
                  <td>{itemProperty.itemType}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{itemProperty.itemDesc}</td>
                </tr>
                <tr>
                  <th>Others</th>
                  <td>{itemProperty.itemAddFields}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className="container">
      <div
        style={{
          background: "rgb(202 246, 255)",
          marginTop: "30px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {data.emEventOrg && (
          <div className="text-center"
          style={{
            fontSize: "56px",
            fontWeight: "900",
          }}>
            {data.emEventOrg}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {data.emEmailId && (
            <span>
              <span style={{ fontWeight: "bold" }}>Email: </span>
              {data.emEmailId}
            </span>
          )}
          {data.emMobile && (
            <span>
              <span style={{ fontWeight: "bold" }}>Mobile: </span>
              {data.emMobile}
            </span>
          )}
        </div>
      </div>

      <div className="wrapper event-expert-main">
        <div className="event-manager-products unregister-user w-100">
          {status === "failed" && (
            <ResponseAlert
              variant={"warning"}
              message={"Something went wrong..."}
            />
          )}
          {status !== "failed" && (
            <div
              className="bg-gradient rounded-3 d-flex align-items-center justify-content-between text-white my-4"
              style={{ padding: "30px", background: theme.light }}
            >
              <SearchBox
                onChange={(e) => search(e.target.value)}
                setState={setSearchTxt}
                value={searchTxt}
                onSearch={() => applyFilteritem()}
                searchData={filterData}
                onSearchSelect={setSearchPopup}
                showSearchItems={Boolean(searchPopup)}
                width={"300px"}
              />
            </div>
          )}
          <ItemList
            items={filterItems}
            onClickOnCard={AssigToItemDetailModal}
            showCost={showPriceBool}
          />
        </div>
      </div>

      <MyVerticallyCenteredModal />
    </div>
  );
}
