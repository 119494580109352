import { colors } from "../../../constants/index";

const Button = ({
  text,
  children,
  onClick,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  fontSize,
  style,
  variant = "primary",
  className,
  disabled,
  type,
  startIcon,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick && onClick(e);
  };
  return (
    <button
      type={type}
      onClick={handleClick}
      className={`border-0 px-3 py-2 d-flex align-items-center justify-content-center rounded-1 gap-2 ${className}`}
      style={{
        ...style,
        background: disabled ? "lightgray" : colors[variant][0],
        color: disabled ? "gray" : colors[variant][1],
        width,
        height,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        fontSize,
        cursor: disabled ? "no-drop" : "pointer",
      }}
    >
      {startIcon}
      {text || children}
    </button>
  );
};

export default Button;
