import { useMemo, useRef, useState } from "react";
import { errObj, handleState } from "../../../functions";

const ImageUpload = ({
  setState,
  image,
  label,
  placeholder,
  required,
  accept = "image/*",
  setError,
  name,
  subField,
  showPreview,
}) => {
  const imageRef = useRef();
  const [err, setErr] = useState(false);
  const [fileName, setFileName] = useState("");
  const borderColor = useMemo(() => (err ? "red" : "#d6d6d6"), [err]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    if (!file) {
      if (!required) return;
      setErr(true);
      handleState(setError, errObj(true, "Image is required"), name, subField);
    }

    const reader = new FileReader();

    // When the file is loaded, convert it to base64
    reader.onload = function (event) {
      const base64String = event.target.result;
      setErr(false);
      handleState(setState, base64String, name, subField);
      handleState(setError, errObj(), name, subField);
    };

    // Read the file as Data URL (base64)
    reader.readAsDataURL(file);
  };

  const clear = (event) => {
    event.stopPropagation();
    if (imageRef.current) {
      imageRef.current.value = null;
    }
    handleState(setState, null, name, subField);
    if (!required) return;
    setErr(true);
    handleState(setError, errObj(true, "Image is required"), name, subField);
  };

  const css = {
    container: {
      display: "grid",
      gridTemplateColumns: "100px auto",
      gap: "5px",
      alignItems: "center",
    },
    uploadFile: { display: "none" },
    chooseBtn: {
      padding: "8px 10px",
      background: "#DDE0E3",
      whiteSpace: "nowrap",
    },
    uploadBtn: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
      border: `1px solid ${borderColor}`,
      cursor: "pointer",
      overflow: "hidden",
    },
    imageBox: {},
    label: {
      textAlign: "end",
      fontSize: "0.7rem",
      fontWeight: "bold",
      color: "#494949",
    },
    placeholder: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };

  return (
    <>
      <div style={css.container}>
        <div style={css.label}>
          {label} {required && "*"}
        </div>
        <div style={css.uploadBtn} onClick={() => imageRef.current.click()}>
          <div style={css.chooseBtn}>Choose File</div>
          <div style={css.placeholder}>
            {image ? fileName || image : placeholder}
          </div>
          {image && (
            <div
              style={{
                marginLeft: "auto",
                width: "50px",
                textAlign: "center",
                color: "white",
                padding: "10px",
                fontSize: "12px",
                fontWeight: "bold",
                rounded: "100px",
                background: "gray",
              }}
              onClick={clear}
            >
              X
            </div>
          )}
        </div>
      </div>

      <input
        style={css.uploadFile}
        type="file"
        onChange={handleImageChange}
        accept={accept}
        ref={imageRef}
      />

      {showPreview && (
        <div style={{ marginTop: "10px", marginInline: "auto" }}>
          {image && (
            <img
              src={image}
              alt={label}
              style={{ maxWidth: "100%", maxHeight: "150px" }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ImageUpload;
