import React from "react";
import Ruppee from "./Ruppee";
import { theme } from "../../constants";

const ItemCard = ({
  onClick,
  image,
  name,
  type,
  cost,
  showCost,
  className,
}) => {
  const css = {
    container: {
      background: "white",
      boxShadow: "0px 8px 24px rgb(0 0 0 / 20%)",
      borderRadius: "8px",
      overflow: "hidden",
      cursor: "pointer",
      position: "relative",
    },
    imageBox: { overflow: "hidden", height: "200px" },
    type: {
      textAlign: "center",
      fontWeight: "bold",
      padding: "8px",
      background: "#404040",
      color: "#FFEA00",
    },
    name: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "20px",
      padding: "14px",
    },
    priceTagBlock: {
      position: "absolute",
      bottom: "50%",
      right: "0px",
    },
    priceTag: {
      background: theme.yellow,
      color: "black",
      padding: "0px 20px",
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      clipPath: 'path("M 100 100 L 100 0 L 17 0 L 0 17.5 L 80 100 Z")',
      width: "90px",
      transformOrigin: "0px 0px",
    },
    priceTagHole: {
      width: "8px",
      height: "8px",
      background: "#333333",
      borderRadius: "100px",
      position: "absolute",
      left: "10px",
    },
  };

  return (
    <div className={className} onClick={onClick}>
      <div style={css.container}>
        <div style={css.imageBox}>
          <img
            src={image ? image : require("../../assets/images/DUMMYIMG.png")}
            alt={name}
          />
        </div>
        <div style={css.type}>{type}</div>
        <div style={css.name}>{name}</div>
        {showCost && (
          <div style={css.priceTagBlock}>
            <div style={css.priceTag}>
              <div style={css.priceTagHole} />
              <Ruppee value={cost} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemCard;
