import Alert from "react-bootstrap/Alert";

function ResponseAlert({ variant, message = "Something went wrong..." }) {
  if (variant === "") return;
  return (
    <Alert variant={variant}>
      {message.slice(0, 300)}
      {message.length > 300 &&
        "..."}
    </Alert>
  );
}

export default ResponseAlert;
