import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "./helper";
//  import {EventData} from "./Redux/Action"
import { useSelector } from "react-redux";
import ResponseAlert from "../components/commonComponent/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "./commonComponent/Form/Switch";
import ItemList from "./commonComponent/ItemList";
import Modal from "./commonComponent/UI/Modal";
import SearchBox from "./commonComponent/UI/SearchBox";
import { MdContentCopy } from "react-icons/md";
import { theme } from "../constants";
import { useQuery } from "react-query";

export default function MainHome() {
  const [data, setData] = useState([]);
  const [selfDetails, setSelfDetails] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [itemProperty, setItemProperty] = useState({});
  const [status, setStatus] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [searchPopup, setSearchPopup] = useState(false);

  const [filterData, setFilterData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [userHash, setUserHash] = useState("");
  const [showPrice, setShowPrice] = useState(false);
  const userDetails = useSelector((state) => state.homeReducer.userdetails);

  const httpOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userDetails.token}`,
    },
  };

  const onSearchSelectItem = (popState, curTxt) => {
    setSearchTxt(curTxt);
    setSearchPopup(popState);
    applyFilteritem(curTxt);
  }

  const fetchItems = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}event/viewitems`,
        httpOptions
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const fetchUserHash = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}users/sharehash`,
        httpOptions
      );
      return response.data.hashValue;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  function AssigToItemDetailModal(item) {
    setItemProperty(item);
    setModalShow(true);
  }

  function search(e) {
    setSearchTxt(e);
    if (e === "") {
      setFilterItems(data);
      setSearchPopup(false);

      return;
    }

    let temp = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].itemType.toLowerCase().includes(e.toLowerCase())) {
        let condition = temp.find(
          (item) => item.toLowerCase() === data[i].itemType.toLowerCase()
        );

        if (!condition) {
          temp.push(data[i].itemType);
        }
      }
      if (data[i].itemName.toLowerCase().includes(e.toLowerCase())) {
        temp.push(data[i].itemName);
      }
    }
    setFilterData(temp);
    if (filterData.length > 0) {
      setSearchPopup(true);
    }
  }
  function applyFilteritem(searchText = searchTxt) {
    // let filterData =
    //   data.length > 0 &&
    //   data.filter((item) => item.itemName.includes(searchTxt));
    // setFilterItems(filterData);
    let filterData =
      data.length > 0 &&
      data.filter(
        (item) =>
          item.itemType.toLowerCase().includes(searchText.toLowerCase()) ||
          item.itemName.toLowerCase().includes(searchText.toLowerCase())
      );
    setFilterItems(filterData);
    setSearchPopup(false);
  }
  function copyUrl() {
    const fullUrl = `${window.location.origin}/Home/auth/${userHash}/${showPrice}`;
    const unsecuredCopyToClipboard = (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(fullUrl);
    } else {
      unsecuredCopyToClipboard(fullUrl);
    }
    //navigator.clipboard.writeText(fullUrl);
    toast.success("Copy to clipboard!!");
  }

  const { data: itemsData, error: itemsError } = useQuery(
    "itemsData",
    fetchItems
  );
  const { data: userHashs, error: userHashError } = useQuery(
    "userHash",
    fetchUserHash
  );
  useEffect(() => {
    if (itemsData) {
      setData(itemsData.emItemsList);
      setSelfDetails(itemsData);
      setFilterItems(itemsData.emItemsList);
    } 
    if (userHashs) {
      setUserHash(userHashs);
    } 
  }, [itemsData, userHashs]);

  function MyVerticallyCenteredModal() {
    return (
      <Modal
        open={modalShow}
        setOpen={setModalShow}
        label="ITEM DETAILS"
        onClose={() => {
          setItemProperty({});
        }}
      >
        <div className="item-details-wrapper">
          <div className="image-wrapper">
            <img
              width="400px"
              height="400px"
              src={itemProperty.itemImagePath}
              alt={itemProperty.itemName}
            />
          </div>
          <div className="item-details">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{itemProperty.itemName}</td>
                </tr>
                <tr>
                  <th>Type</th>
                  <td>{itemProperty.itemType}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{itemProperty.itemDesc}</td>
                </tr>
                <tr>
                  <th>Others</th>
                  <td>{itemProperty.itemAddFields}</td>
                </tr>
                {/* <tr>
                    <th>Cost</th>
                    <td>{itemProperty.itemCost}</td>
                  </tr> */}
                {/* <tr>
                    <th>Material</th>
                    <td>PVC Coated Fabric</td>
                  </tr>

                  <tr>
                    <th>Age Group</th>
                    <td>4-16 year</td>
                  </tr>
                  <tr>
                    <th>Application</th>
                    <td>Amusement Park, Malls</td>
                  </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <div className="wrapper event-expert-main">
        <div className="container">
          <div className="event-manager-products">
            {status === "failed" && (
              <ResponseAlert
                variant={"warning"}
                message={"Something went wrong..."}
              />
            )}
            {status !== "failed" && (
              <div
                className="bg-gradient rounded-3 d-flex flex-wrap align-items-center justify-content-between gap-3 my-4"
                style={{ padding: "30px", background: theme.light }}
              >
                <SearchBox
                  onChange={(e) => search(e.target.value)}
                  value={searchTxt}
                  onSearch={() => applyFilteritem()}
                  searchData={filterData}
                  onSearchSelect={onSearchSelectItem}
                  showSearchItems={Boolean(searchPopup)}
                  width={"300px"}
                />
                <div className="d-flex align-items-center gap-3">
                  <Switch
                    checked={showPrice}
                    setState={setShowPrice}
                    label="Show Price"
                  />
                  <div
                    role="button"
                    className="d-flex align-items-center gap-2"
                    style={{ fontSize: "14px" }}
                    onClick={() => copyUrl()}
                  >
                    <span>Copy URL</span>
                    <span
                      className="rounded-5 d-flex align-items-center justify-content-center text-dark"
                      style={{
                        background: theme.yellow,
                        width: "30px",
                        height: "30px",
                      }}
                    >
                      <MdContentCopy size={16} />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div
        style={{
          background: "lightgray",
          marginTop: "30px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {selfDetails.emEventOrg && (
          <div style={{ fontSize: "50px", fontWeight: "bold" }}>
            {selfDetails.emEventOrg}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {selfDetails.emEmailId && (
            <span>
              <span style={{ fontWeight: "bold" }}>Email: </span>
              {selfDetails.emEmailId}
            </span>
          )}
          {selfDetails.emMobile && (
            <span>
              <span style={{ fontWeight: "bold" }}>Mobile: </span>
              {selfDetails.emMobile}
            </span>
          )}
        </div>
      </div> */}

          <div
            className="mt-2 rounded"
            style={{ background: "rgb(202 246, 255)", marginBottom: "10px" }}
          >
            <div
              className="text-center"
              style={{
                fontSize: "56px",
                fontWeight: "900",
              }}
            >
              {selfDetails.emEventOrg || "Title Unavailable"}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                flexWrap: "wrap",
                padding: "10px",
              }}
            >
              {selfDetails.emEmailId && (
                <span>
                  <span style={{ fontWeight: "bold" }}>Email: </span>
                  {selfDetails.emEmailId}
                </span>
              )}
              {selfDetails.emMobile && (
                <span>
                  <span style={{ fontWeight: "bold" }}>Mobile: </span>
                  {selfDetails.emMobile}
                </span>
              )}
            </div>
          </div>

          <ItemList
            items={filterItems}
            onClickOnCard={AssigToItemDetailModal}
          />
        </div>
      </div>

      <MyVerticallyCenteredModal />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
