const colors = {
  // actual,  respective
  primary: ["#FFEA00", "#212529"],
  secondary: ["#5C636A", "white"],
  light: ["#D3D3D3", "#212529"],
  dark: ["#404040", "white"],
};

const theme = {
  yellow: "#ffcf00",
  light: "#e8e8e8",
};

export { colors, theme };
