import React from "react";
import ItemCard from "./ItemCard";

const ItemList = ({ items, onClickOnCard, showCost, loading }) => {
  const handleClick = (e, i, item) => {
    if (onClickOnCard) {
      onClickOnCard(item);
      return;
    }
  };

  return (
    <div className="row g-4 mb-4">
      {loading && <div className="col-12 text-center">Loading</div>}
      {!loading && items.length === 0 && (
        <div className="text-center col-12">No Item Found</div>
      )}
      {items?.map((item, i) => {
        const { itemCost, itemName, itemType, itemImagePath } = item;
        return (
          <ItemCard
            key={i}
            className="col-lg-3 col-md-4 col-sm-6"
            onClick={(e, i) => handleClick(e, i, item)}
            cost={itemCost}
            name={itemName}
            type={itemType}
            image={itemImagePath}
            showCost={showCost}
          />
        );
      })}
    </div>
  );
};

export default ItemList;
