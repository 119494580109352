import React from "react";
import { theme } from "../../constants";

const Footer = () => {
  return (
    <footer className="d-flex align-items-center justify-content-between px-3 bg-dark bg-gradient py-3">
      <div style={{ color: "#FFEA00" }}>
        ©2022, Event Experts. All Rights Reserved.
      </div>

      <div className="d-flex gap-2">
        <a
          className="p-2 rounded-5 d-flex align-items-center justify-content-center text-black fs-4"
          style={{ width: "40px", height: "40px", background: theme.yellow }}
          target="_blank"
          href="/"
          alt="Facebook"
          aria-label="Facebook"
        >
          <i className="zmdi zmdi-facebook"></i>
        </a>

        <a
          className="p-2 rounded-5 d-flex align-items-center justify-content-center text-black fs-4"
          style={{ width: "40px", height: "40px", background: theme.yellow }}
          target="_blank"
          href="/"
          alt="Facebook"
          aria-label="Instagram"
        >
          <i className="zmdi zmdi-instagram"></i>
        </a>

        <a
          className="p-2 rounded-5 d-flex align-items-center justify-content-center text-black fs-4"
          style={{ width: "40px", height: "40px", background: theme.yellow }}
          target="_blank"
          href="/"
          alt="Facebook"
          aria-label="Youtube"
        >
          <i className="zmdi zmdi-youtube"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
