const urlRegex = /^https?:\/\/[^\s/$.?#].[^\s]*$/i;
const handleState = (setState, value, name, subField) => {
  setState &&
    setState((prev) => {
      if (!name && !subField) {
        return value === "reverse_value" ? !prev : value;
      }

      const temp = { ...prev };
      if (name && subField) {
        temp[subField][name] =
          value === "reverse_value" ? !prev[subField][name] : value;
      }
      if (name && !subField) {
        temp[name] = value === "reverse_value" ? !prev[name] : value;
      }
      return temp;
    });

  return Boolean(setState);
};
const getError = (error) => {
  for (let err in error) {
    if (error[err].hasError) {
      return error[err];
    }

    if (error[err].hasError === undefined && typeof error[err] === "object") {
      const hasNestedError = getError(error[err]);
      if (hasNestedError) {
        return errObj(true, error[err].message);
      }
    }
  }

  return errObj();
};
const errObj = (errorCondition, message) => {
  return {
    hasError: Boolean(errorCondition),
    message: !errorCondition ? "" : message,
  };
};
function capitalize(str) {
  // Convert the first character to uppercase and the rest to lowercase
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
const isURL = (url, useRegex) => {
  try {
    if (useRegex) {
      return urlRegex.test(url);
    }
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export { handleState, getError, errObj, capitalize, isURL };
